import React from "react";
import Layout from "../components/Layout/layout";

const ComingSoon = () => {
  return (
    <>
      <Layout>
        <div className = "w-screen h-screen bg-gradient-to-r from-[#141E30] to-[#243B55] flex flex-col justify-center items-center">
            <h1 className = "text-6xl font-medium text-white">Coming Soon</h1>
        </div>
      </Layout>
    </>
  );
};

export default ComingSoon;
